import useRequest, { CommonResponse } from "../useRequest";
import { computed } from "vue";

const useRole = () => {
  const { data, ...other } = useRequest<CommonResponse<any>>(
    `/app/enum/user-role`
  );
  const enhancedData = computed(() => {
    if (!data.value) return null;
    return data.value.data.data;
  });

  return {
    userRole: enhancedData,
    ...other
  };
};

export default useRole;
