
import { defineComponent, reactive, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";
import { translate } from "@/core/helpers/translate";
import {
  showErrorPopup,
  showSuccessPopup
} from "@/core/helpers/common-helper";
import ApiService from "@/core/services/ApiService";
import { setNewPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import useRole from "@/core/services/compositions/enum/useRole";
import _ from 'lodash';

interface User {
  name: string;
  email: string;
  // is_admin: boolean;
  institution_id: string;
  userable_type: string;
  userable_identifier: string;
  client: string;
  role: string;
}

interface ListItem {
  value: string
  label: string
}

export default defineComponent({
  setup() {
    // init
    const { t } = useI18n();
    const route = useRoute();
    const { userRole } = useRole();
    const submitButton = ref<null | HTMLButtonElement>(null);
    const institution = ref<ListItem[]>([]);
    const client = ref<ListItem[]>([]);
    const loadingInstitution = ref(false)
    const loadingClient = ref(false)

    // breadcrumb
    setNewPageBreadcrumbs(t("management.user.create"), [
      { title: t("management.user.create") }
    ]);

    const create = reactive<User>({
      name: '',
      email: '',
      // is_admin: false,
      institution_id: '',
      userable_type: '',
      userable_identifier: '',
      client: '',
      role: '',
    });

    const resetForm = () => {
      create.name = '';
      create.email = '';
      // create.is_admin = false;
      create.institution_id = '';
      create.userable_type = '';
      create.userable_identifier = '';
      create.client = '';
      create.role = '';
    }

    const handleChangeUserableType = (val) => {
      create.userable_type = val;
    }

    const submitForm = () => {
      if (create.userable_type == '') {
        showErrorPopup({
          title: 'Error',
          text: 'Type cannot empty',
          message: 'Type cannot empty',
        })

        return;
      }

      if (create.userable_type == 'institution' && create.institution_id == '') {
        showErrorPopup({
          title: 'Error',
          text: 'Institution cannot empty',
          message: 'Institution cannot empty',
        })

        return;
      }

      if (create.userable_type == 'client' && create.client == '') {
        showErrorPopup({
          title: 'Error',
          text: 'Client cannot empty',
          message: 'Client cannot empty',
        })

        return;
      }

      if (create.name == '') {
        showErrorPopup({
          title: 'Error',
          text: 'Name cannot empty',
          message: 'Name cannot empty',
        })

        return;
      }

      if (create.email == '') {
        showErrorPopup({
          title: 'Error',
          text: 'Email cannot empty',
          message: 'Email cannot empty',
        })

        return;
      }

      submitButton.value ? (submitButton.value.disabled = true) : null;
      submitButton.value?.setAttribute("data-kt-indicator", "on");

      const payload = {
        name: create.name,
        email: create.email,
        role: create.role,
        // is_admin: create.is_admin,
      };

      if (create.userable_type == 'client') {
        create.userable_identifier = create.client;
      } else if (create.userable_type == 'institution') {
        create.userable_identifier = create.institution_id;
      } else {
        create.userable_identifier = 'xxxxxx';
      }

      ApiService.setHeader();
      ApiService.post(
        `app/entity/user/` + create.userable_type + "/" + create.userable_identifier,
        payload as any
      )
        .then(() => {
          showSuccessPopup({
            title: 'Create Status',
            text: 'Success'
          });

          resetForm();
        })
        .catch(({ response }) => {
          showErrorPopup({
            title: response.data.rc,
            text: response.data.message,
            message: response.data.message,
          })
        })
        .finally(() => {
          submitButton.value ? (submitButton.value.disabled = false) : null;
          submitButton.value?.removeAttribute("data-kt-indicator");
        });
    };

    const searchInstitution = _.debounce((query: string) => {
      if (query && query.length >= 3) {
        loadingInstitution.value = true;
        ApiService.setHeader();
        ApiService.query(`/app/enum/institution`, { params: { name: query } })
          .then((data) => {
            institution.value = data.data.data
          })
          .catch(({ response }) => {
            showErrorPopup({
              title: response.data.rc,
              text: response.data.message,
              message: response.data.message,
            })
          }).finally(() => {
            loadingInstitution.value = false;
        });
      } else {
        institution.value = [];
      }
    }, 800);

    const searchClient = _.debounce((query: string) => {
      if (query && query.length >= 3) {
        loadingClient.value = true;
        ApiService.setHeader();
        ApiService.query(`/app/enum/client`, { params: { name: query } })
          .then((data) => {
            client.value = data.data.data
          })
          .catch(({ response }) => {
            showErrorPopup({
              title: response.data.rc,
              text: response.data.message,
              message: response.data.message,
            })
          }).finally(() => {
            loadingClient.value = false;
        });
      } else {
        client.value = [];
      }
    }, 800);

    return {
      create,
      translate,
      submitForm,
      submitButton,
      resetForm,
      handleChangeUserableType,
      client,
      institution,
      userRole,
      searchInstitution,
      loadingInstitution,
      loadingClient,
      searchClient
    };
  },
  methods: {}
});
